import { reactive, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import consultationDataService from '@/services/consultationDataService'
import { ENoteLength, ENoteType, IConsultation, IPagination } from '@/types'
import { logError } from '@/utils/error-logger'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'

export const useConsultationsStore = defineStore('consultations', () => {
  const { t } = useI18n()

  const selectedConsultationId = ref<string | null>(null)
  const selectedConsultation = ref<IConsultation | null>()
  const recordingConsultationId = ref<string>('')

  const pagination = reactive<IPagination>({
    page: 1,
    size: 10
  })

  const isStopPaginate = ref<boolean>(false)
  const loading = ref<boolean>(false)
  const loadingConsultation = ref<boolean>(false)
  const consolidatingConsultation = ref<boolean>(false)
  const aiProcessingConsultation = ref<boolean>(false)
  const isCreatingNewConsultation = ref<boolean>(false)
  const consultationList = ref<IConsultation[]>([])

  watch(
    [isCreatingNewConsultation, loadingConsultation],
    () => {
      console.log('[isCreatingNewConsultation, loadingConsultation] =====', [
        isCreatingNewConsultation,
        loadingConsultation
      ])
      consolidatingConsultation.value = false
      aiProcessingConsultation.value = false
    },
    { flush: 'sync' }
  )

  const changeConsultationSummaryLength = async (length: ENoteLength) => {
    if (!selectedConsultationId.value) {
      return
    }

    const { data } = await consultationDataService.changeNoteLength(
      selectedConsultationId.value,
      length
    )
    console.log(data)

    selectedConsultation.value = {
      ...selectedConsultation.value,
      summary: data.content,
      noteLength: data.length,
      noteType: data.type
    } as IConsultation
  }
  const changeConsultationNoteType = async (type: ENoteType) => {
    if (!selectedConsultationId.value) {
      return
    }

    const { data } = await consultationDataService.changeNoteType(
      selectedConsultationId.value,
      type
    )
    console.log(data)

    selectedConsultation.value = {
      ...selectedConsultation.value,
      summary: data.content,
      noteLength: data.length,
      noteType: data.type
    } as IConsultation
  }

  const paginateConsultationList = async ({ page }: IPagination = pagination) => {
    if (isStopPaginate.value) return

    const data = await getConsultationList(pagination)

    if (data && data.length) {
      pagination.page = page

      consultationList.value = [...consultationList.value, ...data]
    } else {
      isStopPaginate.value = true
    }
  }

  const clearConsultationList = () => {
    isStopPaginate.value = false
    consultationList.value = []
    pagination.page = 1
    pagination.size = 10
  }

  const getConsultationList = async (pagination: IPagination): Promise<IConsultation[]> => {
    try {
      loading.value = true
      const { data } = await consultationDataService.getAllConsultations(pagination)

      return data
    } catch (error: any) {
      logError(
        "Error: user can't get consultation list",
        'view/consultation/Index.vue:getConsultationList()',
        error?.response?.data
      )
      return []
    } finally {
      loading.value = false
    }
  }

  const selectConsultation = async (newSelectedConsultationId: string | null) => {
    selectedConsultationId.value = newSelectedConsultationId

    if (newSelectedConsultationId) {
      isCreatingNewConsultation.value = false

      try {
        loadingConsultation.value = true

        const { data } =
          await consultationDataService.getConsultationById(newSelectedConsultationId)

        selectedConsultation.value = data
      } catch (error: any) {
        console.log(error)
        logError(
          "Error: user can't get consultation details",
          'view/consultation/Index.vue:selectConsultation()',
          error?.response?.data
        )
      } finally {
        loadingConsultation.value = false
      }
    } else {
      selectedConsultationId.value = null
      selectedConsultation.value = null
    }
  }

  const handleSubmitConsultation = () => {
    consolidatingConsultation.value = true
  }

  const handleExcludeConsultation = (id: string) => {
    consultationList.value = consultationList.value.filter((item) => item._id !== id)

    if (selectedConsultationId.value === id) {
      selectedConsultationId.value = null
      selectedConsultation.value = null
    }
  }

  const removeConsultation = async (id: string) => {
    try {
      await consultationDataService.deleteConsultation(id)
      handleExcludeConsultation(id)

      toaster.success(t('consultationStoreActions.consultationRemoved'))
    } catch (error: any) {
      console.error(error)
      logError(
        "Error: user can't delete consultation",
        'view/consultation/Index.vue:removeConsultation()',
        error?.response?.data
      )
      toaster.error(t('consultationStoreActions.deletingError'))
    }
  }

  async function handleAddConsultation(newConsultation: IConsultation) {
    consultationList.value = [newConsultation, ...consultationList.value]
  }

  async function handleUpdateConsultation(updatedConsultation: IConsultation) {
    const index = consultationList.value.findIndex((item) => item._id === updatedConsultation._id)
    consultationList.value.splice(index, 1, updatedConsultation)
    selectedConsultation.value = updatedConsultation
  }

  const handleCreateNewConsultation = () => {
    isCreatingNewConsultation.value = true
    selectedConsultationId.value = null
    selectedConsultation.value = null
  }

  const handleShowListOfNotes = async () => {
    isCreatingNewConsultation.value = false
    selectedConsultationId.value = null
    selectedConsultation.value = null
    aiProcessingConsultation.value = false
    consolidatingConsultation.value = false

    consultationList.value = await getConsultationList({
      page: 1,
      size: pagination.page * pagination.size
    })
  }

  return {
    pagination,
    selectedConsultationId,
    selectedConsultation,
    recordingConsultationId,
    isCreatingNewConsultation,
    loadingConsultationList: loading,
    loadingConsultation,
    consolidatingConsultation,
    aiProcessingConsultation,
    consultationList,
    isStopPaginate,
    changeConsultationNoteType,
    changeConsultationSummaryLength,
    paginateConsultationList,
    getConsultationList,
    selectConsultation,
    removeConsultation,
    clearConsultationList,
    handleAddConsultation,
    handleUpdateConsultation,
    handleExcludeConsultation,
    handleSubmitConsultation,
    handleCreateNewConsultation,
    handleShowListOfNotes
  }
})
