<template>
  <textarea
    ref="textareaRef"
    :value="props.modelValue"
    class="block h-auto w-full resize-none overflow-auto rounded-md p-3.5 text-black outline-none ring-1 ring-gray-200 transition-all focus:ring-accent-500"
    @input="handleInput"
  />
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, nextTick } from 'vue'
import { useWindowSize, watchDebounced } from '@vueuse/core'

interface Props {
  modelValue?: string
}
const props = withDefaults(defineProps<Props>(), { modelValue: '' })
const emit = defineEmits<{
  'update:model-value': [string | undefined]
}>()

const { width } = useWindowSize()
const textareaRef = ref()

function adjustHeight(el?: HTMLTextAreaElement) {
  if (!el) return

  el.style.height = 'auto'
  el.style.height = el.scrollHeight + 'px'
}
function handleInput(e: any) {
  adjustHeight(e.target as HTMLTextAreaElement)
  emit('update:model-value', e?.target?.value || '')
}

watch(
  () => props.modelValue,
  () => {
    nextTick(() => adjustHeight(textareaRef.value))
  },
  { immediate: true }
)
watchDebounced(
  () => width.value,
  () => {
    console.log('watchDebounced')
    nextTick(() => adjustHeight(textareaRef.value))
  },
  { debounce: 200, maxWait: 1000 }
)

onMounted(() => {
  adjustHeight(textareaRef.value)
})

defineExpose({ textareaRef })
</script>
